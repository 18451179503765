import LinkPhotoNature from "../images/whjeqbqthz6pc0givftf.jpg";
import LinkPhotoAnimals from "../images/DSC_1705.jpg";
import LinkPhotoUrban from "../images/u8pwveq96pirzje6tald.jpg";

function GalleryHome() {
  const handleNatureButton = () => {
    window.location.href = "https://markoivezicphotography.com/nature"; 
  };
  const handleAnimalsButton = () => {
    window.location.href = "https://markoivezicphotography.com/animals"; 
  };
  const handleUrbanButton = () => {
    window.location.href = "https://markoivezicphotography.com/urban"; 
  };

  const naturePhotoCover = LinkPhotoNature
  const animalsCover = LinkPhotoAnimals
  const urbanCover = LinkPhotoUrban

  return (
    <section className="galleryBackground">
      <div className="pozadina">
        <div className="galleryHomeDiv">
          <h3>Nature</h3>
          <img className="linkPhotoHome" src={naturePhotoCover} alt="nature" />
          <h5 style={{color:"white", fontWeight: "normal", width:"80%"}}>Do you have a passion for nature? Here, you can learn about the wonders of our planet and explore its wild places.</h5>
          <button className="viewGallery" onClick={handleNatureButton}>view gallery</button>
        </div>
      </div>
      <div className="pozadina">
        <div className="galleryHomeDiv">
          <h3>Animals</h3>
          <img className="linkPhotoHome" src={animalsCover} alt="animals" />
          <h5 style={{color:"white", fontWeight: "normal", width:"80%"}}>A tribute to wildlife and our beloved pets. <br /> <br /></h5>
          <button className="viewGallery" onClick={handleAnimalsButton} >view gallery</button>
        </div>
      </div>
      <div className="pozadina">
        <div className="galleryHomeDiv">
          <h3>Urban</h3>
          <img className="linkPhotoHome" src={urbanCover} alt="urban" />
          <h5 style={{color:"white", fontWeight: "normal", width:"80%"}}>Discover beautiful architecture, cityscape and street photography in one place. <br /> <br /></h5>
          <button className="viewGallery" onClick={handleUrbanButton} >view gallery</button>
        </div>
      </div>
    </section>
  );
}

export default GalleryHome;
