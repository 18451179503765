import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import ReactGA from "react-ga";
import ModalImg from "../components/ModalImg";
import NavBar from "../components/NavBar.jsx";
import LongMenu from "../components/LongMenu.jsx";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "GTM-KJJ29PR6",
};

TagManager.initialize(tagManagerArgs);

function ForestAlbum() {
  window.dataLayer.push({
    event: "pageview",
  });

  const [image, setImage] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [currentNameIndex, setCurrentNameIndex] = useState(null);
  const [imageName, setImageName] = useState([]);
  const [imageDescription, setImageDescription] = useState([]);
  const [showImageDetails, setShowImageDetails] = useState(null);
  const [clickedImg, setClickedImg] = useState(null);

  const getForestPhotos = () => {
    axios
      .get("https://mi-photography-backend.vercel.app/images/nature-forest")
      .then((response) => {
        setImage(response.data);
      })
      .catch((err) => console.log(err));
  };

  const handleClick = (picture, index) => {
    setCurrentIndex(index);
    setCurrentNameIndex(index);
    setClickedImg(picture.link);
    setImageName(picture.name);
    setImageDescription(picture.description);
  };

  useEffect(() => {
    getForestPhotos();
    ReactGA.pageview(window.location.pathname);
  }, []);

  const handelRotationLeft = () => {
    setCurrentIndex((prev) => prev - 1);
    setClickedImg(image[currentIndex - 1].link);
    setImageName(image[currentIndex - 1].name);
    setImageDescription(image[currentIndex - 1].description);
  };

  const handelRotationRight = () => {
    setCurrentIndex((prev) => prev + 1);
    setClickedImg(image[currentIndex + 1].link);
    setImageName(image[currentIndex + 1].name);
    setImageDescription(image[currentIndex + 1].description);
  };

  return (
    <>
      <NavBar />
      <LongMenu />
      <div className="gocGalleryPage">
        <h3>Forest</h3>
        <hr style={{ width: "50px", margin: "10px" }} />
        <p className="albumDescription">
          Each photograph transports viewers into a realm of lush greenery,
          towering trees, and a sense of tranquility. The album showcases
          diverse ecosystems, from dense tropical rainforests with vibrant
          foliage to misty temperate forests with ancient, moss-covered trees.
          It portrays the interplay of light and shadow, showcasing the magical
          interplay between sunlight filtering through the branches and the
          secrets hidden within the depths of the forest floor. The album allows
          viewers to immerse themselves in the serenity of nature, appreciating
          the intricate details and captivating landscapes that these forests
          have to offer.
        </p>
        <div className="gallery-image">
        {image.map((picture, index) => (
          <div key={index} className="img-box" onClick={() => handleClick(picture, index)}>
            <img
              className="actual-img"
              src={picture.link}
              alt=""
            />
            <div className="transparent-box">
              <div className="caption">
                <p>{picture.name}</p>
                <p className="opacity-low">{picture.description}</p>
              </div>
            </div>
          </div>
          ))}
          {clickedImg && (
            <ModalImg
              image={image}
              handelRotationLeft={handelRotationLeft}
              handelRotationRight={handelRotationRight}
              currentIndex={currentIndex}
              setCurrentIndex={setCurrentIndex}
              clickedImg={clickedImg}
              setClickedImg={setClickedImg}
              imageName={imageName}
              imageDescription={imageDescription}
              showImageDetails={showImageDetails}
              setShowImageDetails={setShowImageDetails}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default ForestAlbum;
