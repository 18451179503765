import * as React from "react";
import Modal from "./Modal";
import { useState } from "react";

//commit comment

export default function FadeMenu() {
  const [openModal, setOpenModal] = useState(false);

  return (
    <div className="longMenu">
      <div
        className="modalMenu"
        onClick={() => {
          !openModal ? setOpenModal(true) : setOpenModal(false);
        }}
      >
        <div
          className={openModal ? "firstMenuLineOpen" : "firstMenuLineClosed"}
        />
        <div
          className={openModal ? "secondMenuLineOpen" : "secondMenuLineClosed"}
        />
        <div
          className={openModal ? "thirdMenuLineOpen" : "thirdMenuLineClosed"}
        />
      </div>
      <Modal open={openModal} onClose={() => setOpenModal(false)} />
    </div>
  );
}
