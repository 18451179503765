import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import UpdateIcon from "@mui/icons-material/Update";
import DeleteIcon from "@mui/icons-material/Delete";
import UpdatePhoto from "../components/UpdatePhoto";
import AddPhoto from "../components/AddPhoto";
import DeletePhoto from "../components/DeletePhoto";
import { useNavigate } from "react-router";

function Dashboard({ isLoggedIn }) {
  const [images, setImages] = useState([]);
  const [gallery, setGallery] = useState(null);
  const [analytics, setAnalytics] = useState(null);
  const [updateModal, setUpdateModal] = useState(null);
  const [imageId, setImageId] = useState(null);
  const [addPhotoModal, setAddPhotoModal] = useState(null);
  const [deletePhotoModal, setDeletePhotoModal] = useState(null);

  // Otvara sve dostupne slike

  const allImages = () => {
    axios
      .get("https://mi-photography-backend.vercel.app/images/")
      .then((response) => {
        setImages(response.data);
      });
  };

  useEffect(() => {
    allImages();
  }, []);

  return (
    <div className="dashboard">
      <div className="dashLeft">
        <ul className="dashList">
          <li
            onClick={() => {
              setAnalytics(false);
              setGallery(true);
            }}
          >
            Edit Slika
          </li>
          <li
            onClick={() => {
              setGallery(false);
              setAnalytics(true);
            }}
          >
            Analitika
          </li>
        </ul>
      </div>
      <div className="dashRight">
        {gallery && (
          <>
            <button
              className="addPhotoButton"
              onClick={() => setAddPhotoModal(true)}
            >
              +
            </button>
            <div className="gallery">
              {images.map((picture, index) => (
                <div className="dashPhotoContainer">
                  <div key={index} className="dashPhoto">
                    <img src={picture.link} alt="" />
                  </div>
                  <UpdateIcon
                    onClick={() => {
                      setUpdateModal(true);
                      setImageId(picture._id);
                    }}
                    fontSize="small"
                  />
                  <DeleteIcon
                    onClick={() => {
                      setImageId(picture._id);
                      setDeletePhotoModal(true);
                    }}
                    fontSize="small"
                  />
                </div>
              ))}
              {updateModal && (
                <UpdatePhoto
                  setUpdateModal={setUpdateModal}
                  imageId={imageId}
                />
              )}
              {addPhotoModal && (
                <AddPhoto setAddPhotoModal={setAddPhotoModal} />
              )}
              {deletePhotoModal && (
                <DeletePhoto
                  setDeletePhotoModal={setDeletePhotoModal}
                  imageId={imageId}
                />
              )}
            </div>
          </>
        )}
        {analytics && (
          <div>
            <h1>U obradi</h1>
          </div>
        )}
      </div>
    </div>
  );
}

export default Dashboard;
