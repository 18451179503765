import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import axios from "axios";

function UpdatePhoto({ setUpdateModal, imageId }) {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("nature");
  const [album, setAlbum] = useState("");
  const [photoUpdated, setPhotoUpdated] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`https://mi-photography-backend.vercel.app/images/${imageId}`)
      .then((response) => {
        const image = response.data;
        setName(image.name);
        setDescription(image.description);
        setCategory(image.category);
        setAlbum(image.album);
      })
      .catch((error) => console.log(error));
  }, [imageId]);

  const handleUpdate = (e) => {
    e.preventDefault();
    const requestBody = { name, description, category, album };

    axios
      .patch(
        `https://mi-photography-backend.vercel.app/images/${imageId}`,
        requestBody
      )
      .then((res) => {
        console.log(res.data);
        setPhotoUpdated(true);
      });
  };

  const handleName = (e) => {
    setName(e.target.value);
  };

  const handleDescription = (e) => {
    setDescription(e.target.value);
  };

  const handleAlbum = (e) => {
    setAlbum(e.target.value);
  };

  return (
    <div className="updatePhoto">
      <p style={{ color: "black" }} onClick={() => setUpdateModal(false)}>
        x
      </p>
      <form onSubmit={handleUpdate} action="">
        <input
          onChange={handleName}
          type="text"
          name="name"
          placeholder="name"
          value={name}
          style={{ height: 30 }}
        />
        <input
          onChange={handleDescription}
          type="text"
          name="description"
          placeholder="description"
          value={description}
          style={{ height: 70 }}
        />
        <input
          onChange={handleAlbum}
          type="text"
          name="album"
          placeholder="album"
          value={album}
          style={{ height: 30 }}
        />
        <div className="categorySelect">
          <p style={{ margin: 0, color: "black", fontSize: 14 }}>category:</p>
          <select style={{ width: 100 }} name="" id="">
            <option onClick={() => setCategory("nature")} value="nature">
              nature
            </option>
            <option onClick={() => setCategory("animals")} value="animals">
              animals
            </option>
            <option onClick={() => setCategory("cityscape")} value="cityscape">
              cityscape
            </option>
          </select>
        </div>
      </form>
      <div>
        <button className="updatePhotoButton" onClick={handleUpdate}>
          submit
        </button>
        {photoUpdated && <p style={{ color: "black" }}>Photo is updated!</p>}
      </div>
    </div>
  );
}

export default UpdatePhoto;
