import GalleryHome from "../components/GalleryHome";
import ContactForm from "../components/ContactForm";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import LongMenu from "../components/LongMenu";
import { Helmet } from "react-helmet";
import { useEffect } from "react";
import { AuthContext } from "../context/authContext";
import { useContext } from "react";
import { useNavigate } from "react-router";
import ReactGA from "react-ga";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "GTM-KJJ29PR6",
};

TagManager.initialize(tagManagerArgs);

function HomePage() {
  window.dataLayer.push({
    event: "pageview",
  });

  const handlePrintButton = () => {
    window.location.href = "https://pixels.com/profiles/marko-ivezic";
  };

  const navigate = useNavigate();

  const { isLoggedIn, authenticateUser } = useContext(AuthContext);

  const logOutUser = () => {
    localStorage.removeItem("authToken");
    navigate("/");
    authenticateUser();
  };

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  return (
    <>
      <div>
        <Helmet>
          <title>Marko Ivezic Photography | Home</title>
          <meta
            name="description"
            content="Welcome to Marko Ivezic Photography, where stunning photography meets the beauty of the world. Explore diverse galleries featuring wildlife, nature, urban landscapes, and more. Experience the art of photography through captivating images that tell a story."
          />
        </Helmet>
      </div>
      <NavBar />
      {isLoggedIn && (
        <div>
          <button onClick={logOutUser} className="logOut">
            Log Out
          </button>
        </div>
      )}
      <LongMenu />
      <section className="cover">
        <div className="title">
          <hr className="hrline" />
          <h1>Nature, Wildlife & Urban</h1>
          <hr className="hrline" />
          <h4 className="podnaslov">photography by Marko Ivezic</h4>
        </div>
        <button className="printButton" onClick={handlePrintButton}>
          PRINTS
        </button>
      </section>
      <GalleryHome />
      <ContactForm />
      <Footer />
    </>
  );
}

export default HomePage;
