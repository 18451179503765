import "./loadingSpinner.css";
import logo from "../../logo.png";

export default function Loading() {
  const Logo = logo;

  return (
    <>
      <div className="loader"></div>
      <img className="logoSpin" src={Logo} width="2500" height="2500" fill="#fff" alt="" />
    </>
  );
}
