import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import ReactGA from "react-ga";
import ModalImg from "../components/ModalImg";
import NavBar from "../components/NavBar";
import LongMenu from "../components/LongMenu.jsx";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "GTM-KJJ29PR6",
};

TagManager.initialize(tagManagerArgs);

function GocAlbum() {
  window.dataLayer.push({
    event: "pageview",
  });

  const [image, setImage] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [currentNameIndex, setCurrentNameIndex] = useState(null);
  const [imageName, setImageName] = useState([]);
  const [imageDescription, setImageDescription] = useState([]);
  const [showImageDetails, setShowImageDetails] = useState(null);
  const [clickedImg, setClickedImg] = useState(null);

  const getGocAlbum = () => {
    axios
      .get("https://mi-photography-backend.vercel.app/images/nature-mountains")
      .then((response) => {
        setImage(response.data);
      })
      .catch((err) => console.log(err));
  };

  const vodopad = image
    .filter((picture) => picture._id === "63ab595c4a13c23ec30781ed")
    .map((picture) => picture.link);

  const handleClick = (picture, index) => {
    setCurrentIndex(index);
    setCurrentNameIndex(index);
    setClickedImg(picture.link);
    setImageName(picture.name);
    setImageDescription(picture.description);
  };

  useEffect(() => {
    getGocAlbum();
    ReactGA.pageview(window.location.pathname);
  }, []);

  const handelRotationLeft = () => {
    setCurrentIndex((prev) => prev - 1);
    setClickedImg(image[currentIndex - 1].link);
    setImageName(image[currentIndex - 1].name);
    setImageDescription(image[currentIndex - 1].description);
  };

  const handelRotationRight = () => {
    setCurrentIndex((prev) => prev + 1);
    setClickedImg(image[currentIndex + 1].link);
    setImageName(image[currentIndex + 1].name);
    setImageDescription(image[currentIndex + 1].description);
  };

  return (
    <>
      <NavBar />
      <LongMenu />
      <div className="gocGalleryPage">
        <h3>Mountains</h3>
        <hr style={{ width: "50px", margin: "10px" }} />
        <p className="albumDescription">
          "Each photo invites you to immerse yourself in the awe-inspiring
          beauty of towering peaks, rugged landscapes, and serene alpine vistas.
          From snow-capped summits reaching towards the heavens to tranquil
          valleys carved by nature's forces, my collection captures the essence
          of mountains in all their grandeur. Discover the perfect harmony of
          untouched wilderness, vibrant colors, and the tranquility that only
          mountains can offer. Let each photograph transport you to a world
          where awe and wonder await at every turn, reminding us of the
          indomitable spirit of nature's most remarkable creations."
        </p>
        <div className="gallery-image">
        {image.map((picture, index) => (
          <div key={index} className="img-box" onClick={() => handleClick(picture, index)}>
            <img
              className="actual-img"
              src={picture.link}
              alt=""
            />
            <div className="transparent-box">
              <div className="caption">
                <p>{picture.name}</p>
                <p className="opacity-low">{picture.description}</p>
              </div>
            </div>
          </div>
          ))}
          {clickedImg && (
            <ModalImg
              image={image}
              handelRotationLeft={handelRotationLeft}
              handelRotationRight={handelRotationRight}
              currentIndex={currentIndex}
              setCurrentIndex={setCurrentIndex}
              clickedImg={clickedImg}
              setClickedImg={setClickedImg}
              imageName={imageName}
              imageDescription={imageDescription}
              showImageDetails={showImageDetails}
              setShowImageDetails={setShowImageDetails}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default GocAlbum;
