import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import SvgIcon from "@mui/material/SvgIcon";
import { useState, useEffect } from "react";

const ModalImg = ({
  clickedImg,
  setClickedImg,
  image,
  imageName,
  imageDescription,
  handelRotationLeft,
  handelRotationRight,
  setCurrentIndex,
  currentIndex,
  showImageDetails,
  setShowImageDetails,
}) => {
  const handleClick = (e) => {
    if (e.target.classList.contains("dismiss")) {
      setClickedImg(null);
    }
  };

  if (imageName.length > 1) {
    setShowImageDetails(true);
  } else {
    setShowImageDetails(null);
  }

  const totalLength = image.length;

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => (document.body.style.overflow = "visible");
  }, []);

  return (
    <>
      <div className="overlay dismiss" onClick={handleClick}>
          <img className="openedImage" src={clickedImg} alt="" />
        {showImageDetails && (
          <div className="modalImageDetails">
            <h3>{imageName}</h3>
            <hr />
            <p>{imageDescription}</p>
          </div>
        )}
        <span className="dismiss" onClick={handleClick}>
          x
        </span>
        {currentIndex > 0 && (
          <div onClick={handelRotationLeft} className="overlay-arrows_left">
            <div>
              <SvgIcon component={ArrowBackIosNewIcon} />
            </div>
          </div>
        )}
        {currentIndex < totalLength - 1 && (
          <div onClick={handelRotationRight} className="overlay-arrows_right">
            <div>
              <SvgIcon component={ArrowForwardIosIcon} />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ModalImg;
