import React from "react";
import "./blogPage.css";
import userImg from "../../logo.png";
import NavBar from "../../components/NavBar";
import LongMenu from "../../components/LongMenu";
import Footer from "../../components/Footer";

const blog = [
  {
    coverPhoto:
      "https://c0.wallpaperflare.com/preview/483/210/436/car-green-4x4-jeep.jpg",
    title: "Why is the Tesla Cybertruck designed the way it is?",
    description: "An exploration into the truck's polarising design",
    category: "technology",
    author: { photo: userImg, name: "Marko Ivezic" },
    date: "06/04/2024",
    views: 20,
    comments: 5,
  },
  {
    coverPhoto:
      "https://www.newsbtc.com/wp-content/uploads/2020/06/mesut-kaya-LcCdl__-kO0-unsplash-scaled.jpg",
    title: "How to Keep Going When You Don’t Know What’s Next",
    description:
      "The future can be scary, but there are ways to deal with that fear.",
    category: "popular",
    author: { photo: userImg, name: "Marko Ivezic" },
    date: "O6/04/2024",
    views: 30,
    comments: 3,
  },
  {
    coverPhoto: "https://images6.alphacoders.com/312/thumb-1920-312773.jpg",
    title: "10 Rules of Dashboard Design",
    description: "Dashboard Design Guidelines",
    category: "design",
    author: { photo: userImg, name: "Marko Ivezic" },
    date: "O6/04/2024",
    views: 25,
    comments: 7,
  },
];

const sortedBlog = blog.sort((a, b) => b.views - a.views);

export default function BlogPage() {
  return (
    <>
      <NavBar />
      <LongMenu />
      <div className="blogCover">
        <h2>Blog</h2>
        <div className="blogCoverHr"></div>
        <h4>
          Welcome to our photography blog, where every click captures a moment,
          and every frame tells a story. Dive into the mesmerizing world of
          visual storytelling, where we explore the art, techniques, and passion
          behind the lens.
        </h4>
      </div>
      <div className="kategorije">
        <button>all</button>
        <button>photography</button>
        <button>mountains</button>
        <button>animals</button>
        <button>astronomy</button>
      </div>
      <div className="blogList">
        <div className="cardsList">
          {blog.map((element) => (
            <div className="blogContainer">
              <div className="card">
                <div className="card-header">
                  <img src={element.coverPhoto} alt="rover" />
                </div>
                <div className="card-body">
                  <span className="tag tag-teal">{element.category}</span>
                  <h4>{element.title}</h4>
                  <p>{element.description}</p>
                  <div className="user">
                    <div className="authorAndDate">
                      <img src={element.author.photo} alt="user" />
                      <div className="user-info">
                        <h5>{element.author.name}</h5>
                        <small>{element.date}</small>
                      </div>
                    </div>
                    <div className="likesAndComments" >
                      <div className="like" >
                        <svg
                          viewBox="0 0 1024 1024"
                          fill="currentColor"
                          height="1.2em"
                          width="1.2em"
                        >
                          <path d="M923 283.6a260.04 260.04 0 00-56.9-82.8 264.4 264.4 0 00-84-55.5A265.34 265.34 0 00679.7 125c-49.3 0-97.4 13.5-139.2 39-10 6.1-19.5 12.8-28.5 20.1-9-7.3-18.5-14-28.5-20.1-41.8-25.5-89.9-39-139.2-39-35.5 0-69.9 6.8-102.4 20.3-31.4 13-59.7 31.7-84 55.5a258.44 258.44 0 00-56.9 82.8c-13.9 32.3-21 66.6-21 101.9 0 33.3 6.8 68 20.3 103.3 11.3 29.5 27.5 60.1 48.2 91 32.8 48.9 77.9 99.9 133.9 151.6 92.8 85.7 184.7 144.9 188.6 147.3l23.7 15.2c10.5 6.7 24 6.7 34.5 0l23.7-15.2c3.9-2.5 95.7-61.6 188.6-147.3 56-51.7 101.1-102.7 133.9-151.6 20.7-30.9 37-61.5 48.2-91 13.5-35.3 20.3-70 20.3-103.3.1-35.3-7-69.6-20.9-101.9zM512 814.8S156 586.7 156 385.5C156 283.6 240.3 201 344.3 201c73.1 0 136.5 40.8 167.7 100.4C543.2 241.8 606.6 201 679.7 201c104 0 188.3 82.6 188.3 184.5 0 201.2-356 429.3-356 429.3z" />
                        </svg>
                        <p>{element.views}</p>
                      </div>
                      <div className="like" >
                        <svg
                          viewBox="0 0 512 512"
                          fill="currentColor"
                          height="1.2em"
                          width="1.2em"
                        >
                          <path d="M256 32C114.6 32 .027 125.1.027 240c0 47.63 19.91 91.25 52.91 126.2-14.88 39.5-45.87 72.88-46.37 73.25-6.625 7-8.375 17.25-4.625 26C5.818 474.2 14.38 480 24 480c61.5 0 109.1-25.75 139.1-46.25 28 9.05 60.2 14.25 92.9 14.25 141.4 0 255.1-93.13 255.1-208S397.4 32 256 32zm.1 368c-26.75 0-53.12-4.125-78.38-12.12l-22.75-7.125-19.5 13.75c-14.25 10.12-33.88 21.38-57.5 29 7.375-12.12 14.37-25.75 19.88-40.25l10.62-28-20.62-21.87C69.82 314.1 48.07 282.2 48.07 240c0-88.25 93.25-160 208-160s208 71.75 208 160S370.8 400 256.1 400z" />
                        </svg>
                        <p>{element.views}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="mostPopular">
          <h5>Most popular</h5>
          {sortedBlog.map((element) => (
            <>
              <div className="mostPopularList">
                <img src={element.coverPhoto} alt="" />
                <div>
                  <p>{element.category}</p>
                  <h6>{element.description}</h6>
                </div>
              </div>
              <div className="mostPopularHr"></div>
            </>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
}
