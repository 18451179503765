import React from "react";
import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router";

function AddPhoto({ setAddPhotoModal }) {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("nature");
  const [album, setAlbum] = useState("");
  const [image, setImage] = useState("");

  const navigate = useNavigate();

  const addNewPhoto = (e) => {
    e.preventDefault();

    const fd = new FormData();
    fd.append("image", image);
    fd.append("name", name);
    fd.append("description", description);
    fd.append("category", category);
    fd.append("album", album);
    const config = {
      url: "https://mi-photography-backend.vercel.app/images",
      method: "POST",
      data: fd,
    };
    axios(config)
      .then((res) => {
        console.log(res.data);
        setName(name);
        setDescription(description);
        setCategory(category);
        setAlbum(album);
        setImage(image);
        navigate("/");
      })
      .catch((e) => {
        console.error(e);
      });
  };

  return (
    <div className="updatePhoto">
      <p style={{ color: "black" }} onClick={() => setAddPhotoModal(false)}>
        x
      </p>
      <form style={{ height: 230 }} onSubmit={addNewPhoto}>
        <input
          type="text"
          name="name"
          placeholder="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          style={{ height: 30 }}
        />
        <input
          type="text"
          name="description"
          placeholder="description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          style={{ height: 70 }}
        />
        <input
          type="text"
          name="album"
          placeholder="album"
          value={album}
          onChange={(e) => setAlbum(e.target.value)}
          style={{ height: 30 }}
        />
        <div className="categorySelect">
          <p style={{ margin: 0, color: "black", fontSize: 14 }}>category:</p>
          <select style={{ width: 100 }} name="" id="">
            <option onClick={() => setCategory("nature")} value="nature">
              nature
            </option>
            <option onClick={() => setCategory("animals")} value="animals">
              animals
            </option>
            <option onClick={() => setCategory("cityscape")} value="cityscape">
              urban
            </option>
          </select>
        </div>
        <label htmlFor="image">image</label>
        <input
          type="file"
          name="image"
          id="image"
          onChange={(e) => setImage(e.target.files[0])}
        />
      </form>
      <button onClick={addNewPhoto}>Add new photo</button>
    </div>
  );
}

export default AddPhoto;
