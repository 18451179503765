import { useContext } from "react";
import { AuthContext } from "../context/authContext";
import { useNavigate } from "react-router-dom";

function NavBar() {
  const { isLoggedIn } = useContext(AuthContext);

  const navigate = useNavigate();

  return (
    <nav className="navBar">
      <div className="navLinks">
        <div className="singleNavLink">
          <li className="fromLeft" onClick={() => navigate("/")}>Home</li>
        </div>
        <div className="singleNavLink">
          <li className="fromLeft" onClick={() => navigate("/nature")}>Nature</li>
        </div>
        <div className="singleNavLink">
          <li className="fromLeft" onClick={() => navigate("/animals")}>Animals</li>
        </div>
        <div className="singleNavLink">
          <li className="fromLeft" onClick={() => navigate("/urban")}>Urban</li>
        </div>
        <div className="singleNavLink">
          <li className="fromLeft" onClick={() => navigate("/about-me")}>About me</li>
        </div>
        {isLoggedIn && (
          <div className="singleNavLink">
            <li className="fromLeft" onClick={() => navigate("/dashboard")}>Dashboard</li>
          </div>
        )}
      </div>
    </nav>
  );
}

export default NavBar;
