import React, { useEffect } from "react";
import {
  HashRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import ReactGA from "react-ga";
import TagManager from "react-gtm-module";
import "./App.css";

import HomePage from "./pages/HomePage";
import NaturePage from "./pages/NaturePage";
import AnimalsPage from "./pages/AnimalsPage";
import UrbanPage from "./pages/UrbanPage";
import NotFound from "./pages/NotFound";
import Login from "./pages/Login";
import IsAdmin from "./components/IsAdmin";
import Dashboard from "./pages/Dashboard";
import GocAlbum from "./pages/GocAlbum";
import ForestAlbum from "./pages/ForestAlbum";
import AboutPage from "./pages/AboutPage";
import BlogPage from "./pages/blog/BlogPage";

// Initialize Google Analytics and Tag Manager
const TRACKING_ID = "G-JB1NNWPQ9P";
ReactGA.initialize(TRACKING_ID);

const tagManagerArgs = { gtmId: "GTM-KJJ29PR6" };
TagManager.initialize(tagManagerArgs);

// Track page views on route change
function usePageTracking() {
  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
    window.dataLayer.push({ event: "pageview" });
  }, [location]);
}

function App() {
  usePageTracking();

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/nature" element={<NaturePage />} />
        <Route path="/nature-mountains" element={<GocAlbum />} />
        <Route path="/nature-forest" element={<ForestAlbum />} />
        <Route path="/animals" element={<AnimalsPage />} />
        <Route path="/urban" element={<UrbanPage />} />
        <Route path="/about-me" element={<AboutPage />} />
        <Route path="/blog" element={<BlogPage />} />
        <Route
          path="/dashboard"
          element={
            <IsAdmin>
              <Dashboard />
            </IsAdmin>
          }
        />
        <Route path="/markoadmin" element={<Login />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
