import React, { useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router";

function DeletePhoto({ imageId, setDeletePhotoModal }) {
  useEffect(() => {
    axios
      .get(`https://mi-photography-backend.vercel.app/images/${imageId}`)
      .then((response) => {
        console.log(imageId);
      })
      .catch((error) => console.log(error));
  }, [imageId]);

  const navigate = useNavigate();

  const deleteImage = () => {
    const config = {
      url: `https://mi-photography-backend.vercel.app/images/${imageId}`,
      method: "DELETE",
    };
    console.log(imageId);
    axios(config)
      .then((res) => {
        console.log(res.data);
        setDeletePhotoModal(false);
        navigate("/");
      })
      .catch((e) => {
        console.error(e);
      });
  };


  return (
    <div className="updatePhoto">
      <h5 style={{ color: "black" }}>Jesi li siguran???</h5>
      <div>
        <button className="deleteButtons" onClick={deleteImage}>da</button>
        <button className="deleteButtons" onClick={() => setDeletePhotoModal(false)}>ne</button>
      </div>
    </div>
  );
}

export default DeletePhoto;
