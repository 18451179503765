import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import axios from "axios";

export default function ContactForm() {
  const [nameValue, setNameValue] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [messageValue, setMessageValue] = useState("");
  const [showRespond, setShowRespond] = useState(false); //obavestenje da je poruka poslata
  const [errorRespond, setErrorRespond] = useState(false)  // obavestenje da poruka nije poslata
  const [showNameError, setShowNameError] = useState(false);
  const [showEmailError, setShowEmailError] = useState(false);
  const [showMessageError, setShowMessageError] = useState(false);

  const handleName = (event) => {
    setNameValue(event.target.value);
    if (nameValue === " ") {
      setShowNameError(true);
    } else {
      setShowNameError(false);
    }
  };
  const handleEmail = (event) => {
    setEmailValue(event.target.value);
    if (emailValue === " ") {
      setShowEmailError(true);
      setErrorRespond(true)
    } else {
      setShowEmailError(false);
    }
  };
  const handleMessage = (event) => {
    setMessageValue(event.target.value);
    if (messageValue === " ") {
      setShowMessageError(true);
      
    } else {
      setShowMessageError(false);
    
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const body = { name: nameValue, email: emailValue, message: messageValue };
    const sendMessage = () => {
      axios
        .post(
          "https://mi-photography-backend.vercel.app/contact",
          body
        )
        .then((response) => {
          setNameValue("");
          setEmailValue("");
          setMessageValue("");
          setShowRespond(true)
          setErrorRespond(false)
        })
        .catch((err) => console.log(err))
    };
    if (nameValue === "") {
      setShowNameError(true);
      
    } else{
     setShowNameError(false)
     setErrorRespond(false)
    }
    if (emailValue === "") {
      setShowEmailError(true);
      
    } else {
      setShowEmailError(false)
      setErrorRespond(false)
    } 
    if (messageValue === "") {
      setShowMessageError(true);
      
    } else {
      setShowMessageError(false)
      setErrorRespond(false)
    }

    if (nameValue === "" || emailValue === "" || messageValue === "") {
      setErrorRespond(true);
      } else {
      setErrorRespond(false);
      }

    if (nameValue !== "" && emailValue !== "" && messageValue !== "") {
    sendMessage();
    }
  };

  return (
    <div className="contactForm">
      <div>
        <h3>GET IN TOUCH</h3>
      </div>
      <div className="horizontalLine">
        <hr />
      </div>
      <div style={{ height: 100, color: "#7a7a7a" }}>
        <p>
          Use contact form if you have any kind of question <br /> I will
          respond as soon as possible
        </p>
      </div>
      <div></div>
      <Box
        onSubmit={handleSubmit}
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "40ch" },
        }}
        noValidate
        autoComplete="off"
      >
        <div className="form" >
          <TextField //name
            required
            id="outlined-multiline-flexible"
            label="Name"
            multiline
            maxRows={4}
            value={nameValue}
            onChange={handleName}
            error={showNameError}
            helperText={showNameError && "This field is required!"}
          />
          <br />
          <TextField //email
            required
            id="outlined-textarea"
            label="Email"
            value={emailValue}
            onChange={handleEmail}
            error={showEmailError}
            helperText={showEmailError && "This field is required!"}
          />
          <br />
          <TextField //message
            required
            id="outlined-multiline-static"
            label="Message"
            multiline
            value={messageValue}
            onChange={handleMessage}
            rows={4}
            error={showMessageError}
            helperText={showMessageError && "This field is required!"}
          />
        </div>
        <div>
        </div>
        <div style={{ paddingTop: 20, paddingBottom: 20 }}>
          <button className="contactFormButton" type="submit">
            Send
          </button>
          {showRespond  && (
            <p style={{ color: "blue" }}>Your message is sent!</p>
          )}
        </div>
      </Box>
    </div>
  );
}
