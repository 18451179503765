import { Helmet } from "react-helmet";

export default function NotFound() {
  const handleHome = () => {
    window.location.href = "https://markoivezicphotography.com/";
  };

  return (
    <>
      <div>
        <Helmet>
          <title>Marko Ivezic Photography</title>
        </Helmet>
      </div>
      <div className="error">
        <h2>404</h2>
        <h3>Page not found</h3>
        <p>
          The page you are looking for doesn't exist or an other error occured.
        </p>
        <button className="homeFromError" onClick={handleHome}>
          Home
        </button>
      </div>
    </>
  );
}
