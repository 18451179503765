import React, { useState, useEffect } from "react";

import axios from "axios";

const AuthContext = React.createContext();

function AuthProviderWrapper(props) {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [user, setUser] = useState(null);

    const storeToken = (token) => {    
      localStorage.setItem('authToken', token);
    }

    const authenticateUser = () => { 
      const storedToken = localStorage.getItem('authToken');
      if (storedToken) {
        axios.get("https://mi-photography-backend.vercel.app/auth/verify",
          { headers: { Authorization: `Bearer ${storedToken}`} }
        )
  
        .then((response) => {
          const user = response.data;
          setIsLoggedIn(true);
          setIsLoading(false);
          setUser(user);
        })
        .catch((error) => {
          setIsLoggedIn(false);
          setIsLoading(false);
          setUser(null);        
        });      
      } else {
          setIsLoggedIn(false);
          setIsLoading(false);
          setUser(null);      
      }   
    }

    useEffect(() => {
      authenticateUser();
     }, []);

    return (                                                   
      <AuthContext.Provider 
        value={{ 
          isLoggedIn,
          isLoading,
          user,
          storeToken,
          authenticateUser,
        }}
      >
        {props.children}
      </AuthContext.Provider>
    )
  }
export { AuthProviderWrapper, AuthContext };